<template>
  <div class="newsinfo">
    <div class="title">
      <div class="title-top">
        新闻中心
      </div>
      <div class="title-description">
        了解有关澄澄的所有最新公司动态
      </div>
    </div>
    <div class="newsinfo-inner">
      <!-- 内容 -->
      <div class="main">
        <div class="main-title">
          {{ val.title }}
        </div>
        <div class="main-2">
          <div class="main-2-1">
            <img class="img1" src="../assets/news/图层 905.png" alt="" />
            {{ val.pushTime }}
          </div>
          <div class="main-2-2">
            <img class="img2" src="../assets/news/图层 906.png" alt="" />
            {{ val.count }}
          </div>
        </div>
        <!-- class="main-content" -->
        <div class="ql-editor main-content" v-html="infoHtml"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      val: {}, //本页对象
      infoHtml: "", //获取的内容 文章
    };
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.getIframe();
    });
  },
  methods: {
    getIframe() {
      console.log(document.querySelector(".ql-video> video"));
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.val = vm.$route.query;
      vm.infoHtml = vm.$route.query.content;
    });
  },
};
</script>

<style lang="stylus" scoped>
@import '../style/newsinfo.styl'
.main-content >>> .ql-video
    width: 100%;
    height 500px
</style>
